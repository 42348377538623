
import { mapGetters } from "vuex";
import { fileTypes } from "@/config";
import UiLazyVideo from "@/components/UI/UiLazyVideo.vue";
export default {
  name: "MarketingBlock",
  components: {
    UiLazyVideo,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fileTypes,
    };
  },
  computed: {
    ...mapGetters({
      appBreakpoints: "general/getAppBreakpoints",
    }),
    currentSlide() {
      return this.data?.slides?.[0] || null;
    },
    fileType() {
      if (this.data?.file?.media_type?.includes(fileTypes.video)) {
        return fileTypes.video;
      }
      return fileTypes.image;
    },
  },
};
