
import { mapGetters } from "vuex";
export default {
  name: "UiLazyVideo",
  props: {
    src: {
      type: [String],
      required: false,
      default: "",
    },
    srcMobile: {
      type: [String, undefined],
      required: false,
      default: "",
    },
    objectFit: {
      type: String,
      default: "cover",
    },
    useLazyLoad: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      appBreakpoints: "general/getAppBreakpoints",
    }),
    videoClasses() {
      if (this.objectFit === "cover") {
        return "cover";
      }
      return "contain";
    },
    videoSrc() {
      return this.appBreakpoints.lgAndUp
        ? this.src
        : this.srcMobile || this.src;
    },
  },
};
